import React, { useState } from 'react'
import { DateTime } from 'luxon'
import Image from 'next/legacy/image'

import { Card } from './Card'
import { Button } from './components/buttons/Button'
import { Loader, loadedAnimationDuration } from './components/Loader'
import { Modal, ModalBody, ModalButtons } from './components/Modal'
import { Title, P } from './components/typography'
import { ButtonLink, InternalLink } from './components/typography/Link'
import { Job, JobType } from './User'
import { timeout } from './utils/timeout'
import { dateFormat } from './pages/api/add-discount'

type JobCardProps = {
  job: Job
  removeJob: () => Promise<void>
}

type JobModalState = 'closed' | 'open' | 'loading' | 'loaded' | 'error'

export const JobCard = React.memo(({ job, removeJob }: JobCardProps) => {
  return (
    <div className="flex flex-col items-center">
      <div className="w-full max-w-lg py-6 sm:p-6 md:w-full md:max-w-none">
        <Card withPadding={false}>
          <JobCardBody job={job} removeJob={removeJob} />
        </Card>
      </div>
    </div>
  )
})

export const JobCardBody = ({ job, removeJob }: JobCardProps) => {
  const [modalState, setModalState] = useState<JobModalState>('closed')

  const onClose = () => {
    setModalState('closed')
  }

  const confirmRemoveDiscount = () => {
    setModalState('open')
  }

  const removeDiscount = async () => {
    setModalState('loading')

    try {
      await removeJob()
    } catch (e) {
      console.error(e)
      setModalState('error')
      return
    }

    setModalState('loaded')

    await timeout(loadedAnimationDuration)

    setModalState('closed')
  }

  const expiryDate = DateTime.fromISO(job.expiry)

  const expired = job.disabled || expiryDate < DateTime.now()

  const jobIcon = () => {
    switch (job.type) {
      case 'PARTNER':
        return (
          <div className="absolute right-0 top-0 w-24 md:w-32 md:rounded-tr overflow-hidden">
            <Image
              src="/profile/partners.png"
              alt="Partner logo"
              layout="responsive"
              width={150}
              height={110}
            />
          </div>
        )

      default:
        return null
    }
  }

  const orderButton = () => {
    switch (job.type) {
      case 'STUDENT':
        return (
          <Button type="link" href="/eat-in-landing/student-discount" accent>
            Order At Table
          </Button>
        )
      default:
        return (
          <Button type="link" href="/order/search/" accent>
            Order now
          </Button>
        )
    }
  }

  const reactivateButton = () => (
    <InternalLink path={discountPath(job.type)} appearance="button">
      Reactivate your discount
    </InternalLink>
  )

  return (
    <div className="px-12 py-10 relative md:px-20">
      {jobIcon()}
      <div className="flex flex-col items-center text-center">
        <Title
          size="card"
          className="mb-4 max-w-sm text-center leading-tight mt-4"
        >
          {formatTitle(job.type, expired)}
        </Title>

        <P className="max-w-sm text-xl">
          {['NHS', 'HSE'].includes(job.type) ? (
            <>
              Your {formatDiscount(job.type)} for{' '}
              <strong>{job.identifier}</strong>{' '}
              {expired ? 'has now expired' : formatExpiryDate(expiryDate)}.
            </>
          ) : job.type == 'PARTNER' || job.type == 'NANDOCA' ? (
            <>
              Your {formatDiscount(job.type)}{' '}
              {expired ? 'has now expired' : formatExpiryDate(expiryDate)}.{' '}
              {formatPartnerDiscount(expiryDate, expired)}
            </>
          ) : job.type == 'STUDENT' ? (
            <>
              {expired
                ? `Your ${formatDiscount(job.type)} has now expired.`
                : "Your 20% off is ready to go and is live. It'll be automatically applied when you checkout."}
            </>
          ) : null}
        </P>

        {expired ? (
          <div className="w-full max-w-sm my-4">{reactivateButton()}</div>
        ) : job.type === 'STUDENT' ? null : (
          <div className="w-full max-w-sm my-4">{orderButton()}</div>
        )}

        {expired ? null : (job.type === 'STUDENT' || job.type === 'NANDOCA') ? null : (
          <ButtonLink
            className="text-base"
            onClick={confirmRemoveDiscount}
            underlineLinks
          >
            Remove discount?
          </ButtonLink>
        )}

        <RemoveDiscountModal
          modalState={modalState}
          onClose={onClose}
          removeDiscount={removeDiscount}
        />
      </div>
    </div>
  )
}

const discountPath = (jobType: JobType): string => {
  switch (jobType) {
    case 'PARTNER':
      return '/discount/nandoca'
    case 'NHS':
      return '/benefits/nhs/activate'
    case 'HSE':
      return '/benefits/hse/activate'
    case 'STUDENT':
      return '/benefits/student/activate'
    default:
      return '/discount/activate'
  }
}

const formatTitle = (jobType: JobType, expired: boolean): string => {
  if (expired) {
    return 'Just to let you know…'
  } else {
    if (jobType == 'PARTNER') {
      return 'Your Partner discount is active'
    } else if (jobType == 'STUDENT') {
      return 'You’re a genius...'
    } else if (jobType == 'NANDOCA') {
      return 'Your Nandoca discount is active'
    } else {
      return 'Thank you for being brilliant!'
    }
  }
}

const formatDiscount = (jobType: JobType): string => {
  switch (jobType) {
    case 'NHS':
      return '20% NHS discount'
    case 'HSE':
      return '20% HSE discount'
    case 'STUDENT':
      return '20% student discount'
    default:
      return 'discount'
  }
}

const formatPartnerDiscount = (expiry: DateTime, expired: boolean): string => {
  if (expired) {
    return 'Your partner discount has expired.'
  } else {
    if (expiry.isValid) {
      return `You'll need to re-verify on ${expiry.toFormat('dd/MM/yy')}.`
    } else {
      return `You'll need to re-verify, but we can't determine when right now.`
    }
  }
}

const formatExpiryDate = (date: DateTime): string => {
  return date.isValid
    ? `is active until ${date.toFormat('dd/MM/yy')}`
    : 'is active'
}

type ModalProps = {
  modalState: JobModalState
  onClose: () => void
  removeDiscount: () => void
}

const RemoveDiscountModal = ({
  modalState,
  onClose,
  removeDiscount,
}: ModalProps) => (
  <Modal
    isOpen={modalState !== 'closed'}
    onClose={onClose}
    animationKey={modalState}
  >
    <ModalBody
      showCloseButton={modalState === 'open' || modalState === 'error'}
    >
      <ModalContent modalState={modalState} />
    </ModalBody>
    <ButtonsContent
      modalState={modalState}
      onClose={onClose}
      removeDiscount={removeDiscount}
    />
  </Modal>
)

const ButtonsContent = ({
  modalState,
  onClose,
  removeDiscount,
}: ModalProps) => {
  switch (modalState) {
    case 'open':
      return (
        <ModalButtons
          primaryCTAText="Yes, I'm sure"
          onPrimaryCTAClick={removeDiscount}
          secondaryCTAText="No, keep it"
          onSecondaryCTAClick={onClose}
        />
      )
    case 'error':
      return (
        <ModalButtons
          primaryCTAText="Try again"
          onPrimaryCTAClick={removeDiscount}
        />
      )
    default:
      return null
  }
}

const ModalContent = ({ modalState }: { modalState: JobModalState }) => {
  switch (modalState) {
    case 'open':
      return (
        <div className="max-w-xs">
          <P className="pb-4 text-2xl text-center">
            Are you sure you want to remove this discount?
          </P>
        </div>
      )

    case 'error':
      return (
        <div className="max-w-xs">
          <P className="pb-4 text-2xl text-center">
            There was a problem removing your discount.
          </P>

          <P className="pt-2 pb-4 text-center">
            Please check your internet connection. If the problem persists, try
            again later.
          </P>
        </div>
      )

    default:
      return (
        <div className="mb-8">
          <Loader loaded={modalState !== 'loading'} />
        </div>
      )
  }
}
